import { forwardRef } from 'react';
import { useTracking } from 'react-tracking';
import Modal, { bindModal, useModal } from 'react-advance-modal';
import 'react-advance-modal/dist/index.css';
import 'components/Modal.scss';

const ModalInstance = forwardRef((props, ref) => {
  const { trackEvent } = useTracking();
  const { callback = () => {}, ...resetProps } = props;

  return (
    <Modal
      ref={ref}
      callback={(controlFunName, options, content) => {
        if (controlFunName === 'push')
          trackEvent({
            type: 'event',
            cat: 'modal',
            val: options.tracking || window.location.pathname + ' ~ other', //options.tracking is passed whenever show/push functions is used
          });

        callback(controlFunName, options, content);
      }}
      {...resetProps}
    />
  );
});

export default ModalInstance;
export { bindModal, useModal };
