import Api from 'Api';

/*

This deals with all the nasties of syncing data to the API, network conditions, and failures

*/

class QueueManagement {
  constructor() {
    this.queryClient = null;
    this.isProcessing = false;
    this.processPromise = null;
    this.trackEvent = () => {};
    return;
  }

  init(queryClient, trackEvent) {
    this.queryClient = queryClient;
    this.trackEvent = trackEvent;
  }

  getIsProcessing() {
    return this.isProcessing;
  }

  setIsProcessing(val) {
    this.isProcessing = val;
    this.queryClient.setQueryData('queue.is_processing', (curStatus) => {
      return val;
    });
  }

  getNextItemKey() {
    const items = this.queryClient.getQueryData('queue');
    if (!items?.length) return '';
    return items[0].key;
  }

  async processItem() {
    const items = this.queryClient.getQueryData('queue');

    if (!items?.length) return Promise.resolve(true);
    const item = items[0];

    const userData = this.queryClient.getQueryData('user.account');

    //if user data isn't yet synced, pause queue because can't verify tasks owners
    if (!userData || userData.phone === undefined)
      return Promise.resolve('pause');

    // Skip task if another one with the same key exist
    if (
      items.filter((e) => [...e.key].join('-') === [...item.key].join('-'))
        .length > 1
    ) {
      this.queryClient.setQueryData('queue', (queue) => {
        queue.shift();
        return queue;
      });
      return this.processItem();
    }

    //if this task belong to different account, remove it
    if (item.owner && item.owner !== userData?.phone) {
      const timestamp = Math.round(new Date().getTime() / 1000);

      this.trackEvent({
        type: 'event',
        cat: 'error-out-of-sync',
        val: `[${timestamp}] Out of sync task belong to different owner, hence task is removed. Original owner: ${item.owner}. Current owner: ${userData?.phone}`,
      });

      this.queryClient.setQueryData('queue', (queue) => {
        queue.shift();
        return queue;
      });
      return this.processItem();
    }

    try {
      let result = await Api.post(item);

      if (result.error || !result.status) {
        console.log('error', result.error);
        this.setIsProcessing(false);

        if (result.error?.status >= 500) {
          const timestamp = Math.round(new Date().getTime() / 1000);
          this.trackEvent({
            type: 'event',
            cat: 'error-server',
            val: `[${timestamp}] Queue task's key: ${item.key} - Error code: ${result.error?.status} - Error message: ${result.error?.data?.message}`,
          });

          this.queryClient.setQueryData('queue', (queue) => {
            queue.shift();
            queue.push({ ...item, error: result.error?.status });
            return queue;
          });

          //prevent circulation: if next item has already an error, stop processing
          if (items[0].error)
            return Promise.resolve('error', result.error?.data?.message);
          else this.processItem();
        }

        return Promise.resolve('error', result.error);
      } else {
        console.log('result no error, item will be removed from queue', result);

        this.queryClient.setQueryData('queue', (queue) => {
          queue.shift();
          return queue;
        });
        return this.processItem();
      }
    } catch (error) {
      return Promise.resolve('error', error.response);
    }
  }

  async process() {
    // check if we are already processing the queue
    if (this.isProcessing || navigator.onLine === false) return;

    this.setIsProcessing(true);
    const items = this.queryClient.getQueryData('queue');
    if (!items?.length) {
      this.setIsProcessing(false);
      return;
    }

    //processItem will keep calling itself untill there's nothing left in the queue (or an error occurs)
    this.processPromise = this.processItem().then((res, error) => {
      this.setIsProcessing(false);
      return res;
    });

    return this.processPromise;
  }

  async update(key, newItem) {
    const userData = this.queryClient.getQueryData('user.account');

    this.queryClient.setQueryData('queue', (queue) => {
      if (!queue || !queue.length) queue = [];

      queue.push({
        key: key,
        owner: userData?.phone,
        item: newItem,
      });

      return queue;
    });

    if (this.isProcessing) return this.processPromise;

    return this.process();
  }

  clear() {
    return this.queryClient.setQueryData('queue', []);
  }

  length() {
    return this.queryClient.getQueryData('queue')?.length;
  }
}

export default new QueueManagement();
