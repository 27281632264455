import React, { useState, useEffect } from 'react';
import { Container, Item } from 'components/Grid';
import './forms.scss';

/**
 * @param {object} props
 * @param {Array.<{key: string, name: string, image_url: string?}>} props.items
 * @param {Function} props.handler
 * @param {string|"key"} [props.dataKeyAttributeName]
 * @param {boolean=} [props.checkedIcon] whether to use checked-icon for radio circle of the selected item
 * @param {boolean=} [props.withImages] whether the items have images, which will change the layout of the component to grid view
 * @returns
 */
export default function RadioGroup(props) {
  const [activeItem, setActiveItem] = useState(props.activeItem);
  const key = props.dataKeyAttributeName || 'key';

  useEffect(() => {
    if (typeof props.handler === 'function') {
      props.handler(activeItem);
    }
  }, [activeItem]);

  return (
    <>
      <section
        className={`radio-group ${props.withImages ? '--withImages' : ''}`}
      >
        <Container className="radios">
          {props.items &&
            props.items.map((data) => (
              <Item key={data[key]}>
                <div
                  onClick={() => setActiveItem(data[key])}
                  className={`form-item radio ${
                    data[key] === activeItem && 'active'
                  }`}
                >
                  <input
                    onChange={() => setActiveItem(data[key])}
                    id={`radio-${props.name}_${data.name}`}
                    type="radio"
                    name={props.name}
                    value={data[key]}
                    checked={activeItem === data[key]}
                  />
                  <label
                    className={props.checkedIcon ? '--checked-icon' : ''}
                    htmlFor={`radio-${props.name}_${data.name}`}
                  >
                    {props.withImages && (
                      <img src={data.image_url} alt={data.name} />
                    )}
                    {data.name}
                  </label>
                </div>
              </Item>
            ))}
        </Container>
      </section>
    </>
  );
}
