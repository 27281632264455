import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient, useMutation } from 'react-query';
import { useModal } from 'components/Modal';
import BackButton from 'components/BackButton';
import Button from 'components/Button';
import Spinner from 'components/Spinner';
import FormItem from 'components/FormItem';
import { changeLanguage } from 'components/LanguageSelector';
import Verification from 'pages/Registration/Verification';
import FormItemPhone from 'components/fields/Phone';
import { Formik, Form } from 'formik';
import ResetPassword from 'pages/Registration/ResetPassword';
import * as Yup from 'yup';
import Api from 'Api';

export default function LoginForm(props) {
  const { t, i18n } = useTranslation('common');
  const queryClient = useQueryClient();
  const modal = useModal();

  const ValidationSchema = Yup.object().shape({
    username: Yup.string().required(t('validation-required')),
    password: Yup.string()
      .min(8, t('validation-password'))
      .required(t('validation-required')),
  });

  const { isLoading, mutate: postForm } = useMutation(({ values, actions }) => {
    return Api.loginUser(values)
      .then(async () => {
        let lastUserDataPhone = localStorage.getItem('user-phone');
        if (lastUserDataPhone !== values.username)
          queryClient.removeQueries({ inactive: true }); // clear last user cache

        const userData = await Api.getUser();
        queryClient.setQueryData('user.account', userData);
        localStorage.setItem('user-phone', userData.phone);
        queryClient.setQueryData('user.authenticated', true);
        changeLanguage(userData.language, i18n);

        if (!userData['phone_verified']) {
          const verify = (code) =>
            Api.verifyUserPhone({ verification_code: code }).then(async () => {
              queryClient.setQueryData('user.account', {
                phone_verified: true,
                ...userData,
              });
              modal.close({ animation: false });
            });

          modal.push(
            <Verification
              verify={verify}
              length={6}
              verifyButtonText={t('account-verification-action-button')}
              secondaryButtonText={t('account-verification-skip-button')}
              secondaryButtonOnClick={() => modal.close({ animation: false })}
            />,
            {
              tracking:
                window.location.pathname +
                ' ~ login: phone-verification-reminder',
            }
          );
        } else modal.close({ animation: false });
      })
      .catch((error) => {
        actions.setFieldValue('password', '', false);
        actions.setErrors({ password: error.response.data?.message });
      });
  });

  return (
    <>
      <header>
        <BackButton cb={modal.pop} />
        <div role="doc-subtitle">{t('login-subtitle')}</div>
        <h1>{t('login-title')}</h1>
        <div role="doc-description">{t('login-description')}</div>
      </header>
      <main style={{ position: 'relative' }}>
        {isLoading && <Spinner floating />}
        <h2>{t('login-form-heading')}</h2>
        <Formik
          initialValues={{
            username: props?.values?.username || '',
            password: props?.values?.password || '',
          }}
          validationSchema={ValidationSchema}
          onSubmit={(values, actions) => postForm({ values, actions })} //because useMutation function accept only one argument!
        >
          {({ values }) => (
            <Form id="login-form">
              <FormItemPhone
                name="username"
                label={t('form-phone')}
                id="form-phone"
              />
              <FormItem
                type="password"
                name="password"
                label={t('form-password')}
              />
            </Form>
          )}
        </Formik>
        <button
          style={{
            textDecoration: 'underline',
            fontSize: 'var(--font-size-large)',
            marginTop: 'var(--gap-double)',
          }}
          onClick={() =>
            modal.push(<ResetPassword />, {
              tracking:
                window.location.pathname +
                ' ~ forgot-password: phone-number-form',
            })
          }
        >
          {t('login-forgot-password')}
        </button>
      </main>
      <footer>
        <Button type="submit" form="login-form" disabled={isLoading}>
          {t('login-action-button')}
        </Button>
      </footer>
    </>
  );
}
