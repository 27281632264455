import React from 'react';
import { useTranslation } from 'react-i18next';
import { useModal } from 'components/Modal';
import { useMutation } from 'react-query';
import BackButton from 'components/BackButton';
import Button from 'components/Button';
import Spinner from 'components/Spinner';
import FormItem from 'components/FormItem';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Api from 'Api';

/**
 *
 * @param {*} props
 * @param {number} props.phone
 * @param {number} props.verificationCode
 * @returns
 */
export default function CreatePassword(props) {
  const { t } = useTranslation('common');
  const modal = useModal();

  const ValidationSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, t('validation-password'))
      .required(t('validation-required')),
    password_confirmation: Yup.string()
      .oneOf([Yup.ref('password'), null], t('validation-passwords-match'))
      .required(t('validation-required')),
  });

  const { isLoading, mutate: postForm } = useMutation(({ values, actions }) => {
    return Api.resetPassword({
      ...values,
      phone: props.phone,
      token: props.verificationCode,
    })
      .then(() => {
        modal.pop();
        modal.pop();
        modal.pop();
      })
      .catch((error) => {
        actions.setFieldValue('password', '');
        actions.setFieldTouched('password', false);

        actions.setFieldValue('confirm_password', '');
        actions.setFieldTouched('confirm_password', false);

        actions.setErrors(error.response.data?.errors);
      });
  });

  return (
    <>
      <header>
        <BackButton cb={modal.pop} />
        <div role="doc-subtitle">{t('create-password-subtitle')}</div>
        <h1>{t('create-password-title')}</h1>
        <div role="doc-description">{t('create-password-description')}</div>
      </header>
      <main style={{ position: 'relative' }}>
        {isLoading && <Spinner floating />}
        <h2>{t('create-password-form-heading')}</h2>
        <Formik
          initialValues={{ password: '', password_confirmation: '' }}
          validationSchema={ValidationSchema}
          onSubmit={(values, actions) => postForm({ values, actions })} //because useMutation function accept only one argument!
        >
          {({ values }) => (
            <Form id="create-password-form">
              <FormItem
                label={t('form-new-password')}
                name="password"
                type="password"
              />
              <FormItem
                label={t('form-password-confirmation')}
                name="password_confirmation"
                type="password"
              />
            </Form>
          )}
        </Formik>
      </main>
      <footer>
        <Button type="submit" form="create-password-form" disabled={isLoading}>
          {t('create-password-action-button')}
        </Button>
      </footer>
    </>
  );
}
