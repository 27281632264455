import React, { useState } from 'react';
import { useField, ErrorMessage } from 'formik';
import './Code.scss';

/**
 * General styled form item
 * @param {object} props
 * @param {string} props.label
 * @param {string} props.type
 * @param {string} props.name
 * @param {string} props.length
 * @returns
 */
export default function Code({
  label = '',
  name = 'code',
  type = 'text',
  length = 5,
  ...props
}) {
  const [field, meta, helpers] = useField({ name, type, ...props });
  const [value, setValue] = useState('');

  return (
    <div>
      <div
        className="form-item-code form-item"
        style={{ '--code-length': length }}
      >
        <input
          id={name}
          {...field}
          onChange={(e) => {
            if (type === 'number') {
              let match = String(e.target.value).match(/[0-9]+/g);
              e.target.value = match ? match[0].substring(0, length) : '';
            }
            if (props.pattern) {
              let regex = new RegExp(props.pattern, 'g');
              let match = String(e.target.value).match(regex);
              e.target.value = match ? match[0].substring(0, length) : '';
            }
            setValue(e.target.value);
            field.onChange(e);
          }}
        />
        <label htmlFor={name}>
          {[...Array(length)].map((_, i) => (
            <div className={value.length === i ? '--active' : ''} key={i}>
              {value.charAt(i)}
            </div>
          ))}
        </label>
      </div>
      <ErrorMessage
        name={name}
        render={(msg) => <div className="error-msg">{msg}</div>}
      />
    </div>
  );
}
