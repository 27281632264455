import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import Button from 'components/Button';
import Spinner from 'components/Spinner';
import { useModal } from 'components/Modal';
import CodeFormItem from 'components/fields/Code';
import BackButton from 'components/BackButton';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

/**
 *
 * @param {{}} props
 * @param {({code} => {}))} props.verify function to verify the entered code
 * @param {number} props.length the length of the code
 * @param {boolean} props.withBackButton default to false
 * @param {string} props.verifyButtonText
 * @param {string} props.secondaryButtonText if no text is given, then this button will be omitted
 * @param {string} props.secondaryButtonOnClick callback when the button is clicked
 * @returns
 */
export default function Verification(props) {
  const { t } = useTranslation('common');
  const modal = useModal();
  const {
    verify = async () => {},
    length = 5,
    withBackButton = false,
    verifyButtonText = t('verification-action-button'),
    secondaryButtonText,
    secondaryButtonOnClick,
  } = props;

  const ValidationSchema = Yup.object().shape({
    code: Yup.string()
      .min(length, 'Too Short!')
      .max(length, 'Too Long!')
      .required('Required field!'),
  });

  const { isLoading, mutate: postForm } = useMutation(({ values, actions }) => {
    return verify(values.code).catch((error) => {
      actions.setFieldValue('code', '', false);
      actions.setErrors({
        code: error.response.data?.error_message,
      });
    });
  });

  return (
    <>
      <header>
        {withBackButton && <BackButton cb={modal.pop} />}
        <div role="doc-subtitle">{t('verification-subtitle')}</div>
        <h1>{t('verification-title')}</h1>
        <div role="doc-description">{t('verification-description')}</div>
      </header>
      <main style={{ position: 'relative' }}>
        {isLoading && <Spinner floating />}
        <h2>{t('verification-form-heading')}</h2>
        <Formik
          initialValues={{ code: '' }}
          validationSchema={ValidationSchema}
          onSubmit={(values, actions) => postForm({ values, actions })} //because useMutation function accept only one argument!
        >
          {() => (
            <Form id="verification-form">
              <CodeFormItem name="code" type="number" length={length} />
            </Form>
          )}
        </Formik>
        <p>{t('verification-help-text')}</p>
      </main>
      <footer>
        <Button type="submit" form="verification-form" disabled={isLoading}>
          {verifyButtonText}
        </Button>
        {secondaryButtonText && (
          <Button outline onClick={secondaryButtonOnClick}>
            {secondaryButtonText}
          </Button>
        )}
      </footer>
    </>
  );
}
