import { useState, useEffect, useRef } from 'react';
import './Select.scss';

/**
 * @param {object} props these will be passed as props to container element
 * @returns
 */
export default function Select(props = {}) {
  let {
    form,
    options = [],
    optionValueKey = 'value',
    optionText = 'label',
    defaultOption,
    defaultValue,
    handleChange: handleChangeCallback,
    ...restAttr
  } = props;
  const selectRef = useRef(null);
  const dropdownRef = useRef(null);
  const [isOpen, setOpen] = useState();
  if (defaultOption) options = [defaultOption, ...options];

  const handleChange = (e) => {
    if (e.currentTarget.attributes.disabled) return;

    if (form)
      form.setFieldValue(
        restAttr.name,
        e.currentTarget.getAttribute('data-key')
      );
    else {
      if (e.currentTarget.getAttribute('data-key')) {
        selectRef.current.value = e.currentTarget.getAttribute('data-key');
      }
    }

    if (typeof handleChangeCallback == 'function')
      handleChangeCallback(e.currentTarget.getAttribute('data-key'));

    e.currentTarget.parentElement.blur();
    setOpen(false);
  };

  const handleSelect = (e) => {
    e.preventDefault();

    //compute height of dropdown
    let h =
      window.innerHeight - selectRef.current.getBoundingClientRect().bottom - 4;
    dropdownRef.current.style.setProperty(
      '--dropdown-height',
      (h < 230 ? h : 230) + 'px'
    );
    e.currentTarget.focus();
    setOpen(!isOpen);
  };

  const getValueLabel = (key) => {

    let val = options.find((val, index)=> {
      if (val.value == key) {
        return val;
      }
    });
    if (!val && options) {
      val = options[0];
    }
    return val ? val.label : 'select';
  }

  useEffect(() => {
    const handleResize = () => {
      const containerElement = selectRef.current.parentElement.parentElement;
      const style = window.getComputedStyle(containerElement);
      dropdownRef.current.style.setProperty('--dropdown-width', style.width);
    };
    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize); // remove listener to prevent memory leak
  }, []);

  return (
    <div className={`selector ${!isOpen ? 'selector--close' : ''}`}>
      <input type="hidden"
        ref={selectRef}
        defaultValue={defaultValue}
        {...restAttr}
      />
      <div className="select-label"
        onClick={handleSelect}>
          {getValueLabel({...restAttr}.value || (selectRef.current && selectRef.current.value ? selectRef.current.value : 'select'))}
      </div>
      <div className="selector__dropdown" ref={dropdownRef} tabIndex={1}>
        {options.map((option) => (
          <div
            className={`selector__dropdown__option ${
              selectRef.current &&
              // eslint-disable-next-line eqeqeq
              selectRef.current.value == option[optionValueKey] // use "==" because the types can be different (eg. '1' == 1)
                ? '--selected'
                : ''
            }`}
            data-key={option[optionValueKey]}
            key={option[optionValueKey] || Math.random()}
            onClick={handleChange}
            {...option.props}
          >
            {option[optionText]}
          </div>
        ))}
      </div>
    </div>
  );
}
