import React from 'react';
import { useQuery } from 'react-query';
import { NavLink } from 'react-router-dom';
import { ReactComponent as IconHome } from 'assets/menu/home.svg';
import { ReactComponent as IconCrops } from 'assets/menu/crops.svg';
import { ReactComponent as IconUpgrades } from 'assets/menu/upgrades.svg';
import { ReactComponent as IconProfile } from 'assets/menu/profile.svg';
import { ReactComponent as NotificationDot } from 'assets/icons/notification-dot.svg';

import { Container, Grid, Item } from 'components/Grid';
import './Menu.scss';

export default function Menu() {
  const { data: notifications } = useQuery('user.data.notifications');

  return (
    <nav className="nav-main">
      <Container>
        <Grid cols="4">
          <Item>
            <NavLink to="/">
              <div style={{ position: 'relative' }}>
                <IconHome></IconHome>
                {Array.isArray(notifications) && notifications.length > 0 && (
                  <NotificationDot
                    style={{ position: 'absolute', top: 0, right: 0 }}
                  />
                )}
              </div>
            </NavLink>
          </Item>
          <Item>
            <NavLink to="/crops">
              <IconCrops></IconCrops>
            </NavLink>
          </Item>
          <Item>
            <NavLink to="/upgrades">
              <IconUpgrades></IconUpgrades>
            </NavLink>
          </Item>
          <Item>
            <NavLink to="/profile">
              <IconProfile></IconProfile>
            </NavLink>
          </Item>
        </Grid>
      </Container>
    </nav>
  );
}
