import { useTranslation } from 'react-i18next';
import { useModal } from 'components/Modal';
import LoginForm from 'pages/Registration/LoginForm';
import BackButton from './BackButton';
import Button from './Button';

export default function ModalMessageLoginSync() {
  const { t } = useTranslation('common');
  const msgModal = useModal('msg');
  const modal = useModal();
  return (
    <div className="modal-mini">
      <header>
        <h3>{t('message-outofsync-login-title')}</h3>
      </header>
      <div className="content">{t('message-outofsync-login-content')}</div>
      <div className="bottom">
        <BackButton
          cb={() => {
            // not deleting the item through cancel anymore, user can manage themselves
            msgModal.close();
          }}
        >
          {t('button-cancel')}
        </BackButton>

        <Button
          type="submit"
          small
          onClick={() => {
            msgModal.close();
            modal.push(
              <LoginForm
                values={{ username: localStorage.getItem('user-phone') }}
              />
            );
          }}
        >
          {t('button-resync')}
        </Button>
      </div>
    </div>
  );
}
