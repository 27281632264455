import localforage from 'localforage';

class fileManagement {
  constructor() {
    return;
  }

  setFile(key, file, cb) {
    const blob = new Blob([file], { type: file.type });

    //const key = `file-${file.name}`;
    localforage
      .setItem(key, blob)
      .then(function () {
        return localforage.getItem(key);
      })
      .then((value) => cb(value))
      .catch(function (err) {
        console.log('err', err);
        // we got an error
      });
  }

  deleteFile() {}

  getFile(key) {
    return localforage
      .getItem(key)
      .then((value) => {
        return value;
      })
      .catch((err) => {
        console.log('err', err);
      });
  }

  uploadImage(el, cb) {
    if (el.files.length === 0) return;
    for (let i = 0; i < el.files.length; i++) {
      const file = el.files[i];
      const newImageKey = `${new Date().getTime()}_${file.name}`;
      this.setFile(newImageKey, file, (result) => {
        cb(newImageKey);
      });
    }
    el.value = ''; // reset the file field
  }

  deleteImage(source, cb) {
    if (source.indexOf('http') !== 0) {
      this.deleteFile(source);
    }
    cb();
  }
}

export default new fileManagement();
