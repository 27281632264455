import { forwardRef } from 'react';
import { Field, ErrorMessage } from 'formik';
import Select from 'components/fields/Select';

/**
 * General styled form item
 * @param {object} props
 * @param {string} props.label
 * @param {string} props.type
 * @param {string} props.name
 * @returns
 */
export default forwardRef(
  ({ label = '', type = 'text', name = '', style = {}, ...props }, ref) => (
    <div>
      <div className="form-item" ref={ref} style={style}>
        <label htmlFor={name}>{label}</label>
        {type === 'select' ? (
          <Field type={type} id={name} name={name}>
            {({ field, form }) => <Select {...field} form={form} {...props} />}
          </Field>
        ) : (
          <Field type={type} id={name} name={name} {...props}>
            {props.children}
          </Field>
        )}
      </div>
      <ErrorMessage
        name={name}
        render={(msg) => <div className="error-msg">{msg}</div>}
      />
    </div>
  )
);
