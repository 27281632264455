import { useState } from 'react';
import globe from 'assets/icons/globe.png';
import { ReactComponent as ArrowSVG } from 'assets/icons/select-arrow.svg';
import { useTranslation } from 'react-i18next';
import './LanguageSelector.scss';

/**
 * @param {object} props these will be passed as props to container element
 * @param {'left'|'center'|'right'} props.position, to specify the dropdown position: 'left', 'center' or (default) 'right'
 * @param {function} props.callback callback with first argument is selected language key
 * @returns
 */
export default function LanguageSelector(props) {
  const { position = 'right', callback = () => {}, style, ...restAttr } = props;
  const { i18n } = useTranslation('common');
  const [isOpen, setOpen] = useState();
  const [lang, setLang] = useState(i18n.language);
  const options = {
    km: 'ភាសាខ្មែរ',
    'en-GB': 'English',
  };

  const changeLang = (e) => {
    let langKey = e.currentTarget.getAttribute('data-value');
    setLang(langKey);
    changeLanguage(langKey, i18n);
    setOpen(false);
    callback(langKey);
  };

  return (
    <div
      className={`language-selector language-selector--${position} ${
        isOpen ? 'language-selector--open' : ''
      }`}
      style={{ '--language-selector-position': position, ...style }}
      {...restAttr}
    >
      <img
        src={globe}
        alt="change language"
        className="language-selector__globe-icon"
      />
      <ArrowSVG className="language-selector__arrow-icon" />
      <select
        onMouseDown={(e) => {
          e.preventDefault();
          setOpen(!isOpen);
        }}
        value={lang}
        onChange={setLang}
      >
        {Object.entries(options).map(([key, val]) => (
          <option value={key} key={key}>
            {val}
          </option>
        ))}
      </select>
      <div className="language-selector__dropdown">
        {Object.entries(options).map(([key, val]) => (
          <div
            className={`language-selector__dropdown__option ${
              lang === key ? '--selected' : undefined
            }`}
            data-value={key}
            key={key}
            onClick={changeLang}
          >
            {val}
          </div>
        ))}
      </div>
    </div>
  );
}

export function changeLanguage(langKey, i18n) {
  if (langKey === 'en') langKey = 'en-GB';

  i18n?.changeLanguage(langKey);
  localStorage.setItem('locale', langKey);
  localStorage.setItem('localeDateFns', langKey.replaceAll('-', ''));
  document.body.setAttribute('data-lang', langKey);
}
