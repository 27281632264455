import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ArrowLeft } from 'assets/icons/arrow-left.svg';
import './BackButton.scss';

export default function BackButton(props) {
  const navigate = useNavigate();
  const { children, cb, routePath, floating, outline, ...restAttributes } =
    props;
  const isImageButton = !children;
  const back = null; // we're not using this mechanism atm

  const TriggerButton = () => {
    return isImageButton ? <ArrowLeft></ArrowLeft> : children;
  };

  const onClickHandler = () => {
    if (back) {
      // overrides props, can be set in <Link state={{back: '/path/name'}}>
      navigate(back);
    } else if (typeof cb === 'function') {
      // custom callback
      cb();
    } else if (routePath) {
      // fixed path to go back to
      navigate(routePath);
    }
  };

  return (
    <div>
      <div
        onClick={onClickHandler}
        className={`back-button ${isImageButton ? 'image-button' : ''} ${
          outline ? 'back-button--outline' : ''
        }`}
        {...restAttributes}
      >
        <TriggerButton />
      </div>
      {!floating && isImageButton && (
        <div className="back-button back-button__placeholder"></div>
      )}
    </div>
  );
}
