import React from 'react';
import { ReactComponent as SpinnerSVG } from 'assets/loading-spinner.svg';
import './Button.scss';

export default function Button({
  children,
  className = '',
  small,
  text,
  callback,
  outline,
  myRef,
  loading,
  ...otherAttributes
}) {
  const extraClassNames = [];
  if (outline) extraClassNames.push('btn--outline');
  if (small) extraClassNames.push('btn--small');
  if (!className.length) {
    className = 'btn-default';
  }

  return (
    <button
      ref={myRef}
      onClick={callback}
      className={`${className} ${extraClassNames.join(' ')} ${
        loading ? 'btn--loading' : ''
      }`}
      disabled={loading}
      /* use {...otherAttributes} to pass attributes like 'disabled' */
      {...otherAttributes}
    >
      {loading ? <SpinnerSVG /> : children ? children : text}
    </button>
  );
}
