import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button';
import RegisterForm from 'pages/Registration/RegisterForm';
import LoginForm from 'pages/Registration/LoginForm';
import LanguageSelector from 'components/LanguageSelector';
import { useModal } from 'components/Modal';
import { ReactComponent as RiceBagSVG } from 'assets/registration/rice-bag.svg';
import Api from 'Api';

export default function Registration() {
  const { t } = useTranslation('common');
  const modal = useModal();

  useEffect(() => {
    Api.getTokenCookie();
  }, []);

  return (
    <main className="page" style={{ paddingBottom: '4rem' }}>
      <header>
        <LanguageSelector
          position="right"
          style={{ marginLeft: 'auto', marginBottom: 'var(--gap)' }}
        />
        <div role="doc-subtitle">{t('app-name')}</div>
        <h1>{t('registration-getting-started')}</h1>
        <div role="doc-description" style={{ padding: '0 2rem' }}>
          {t('registration-getting-started-description')}
        </div>
      </header>
      <RiceBagSVG style={{ marginTop: 33 }} />
      <footer>
        <Button
          onClick={() =>
            modal.push(<RegisterForm />, {
              tracking:
                window.location.pathname + ' ~ create-account: choose-role',
            })
          }
          outline
        >
          {t('registration-create-account')}
        </Button>
        <Button
          onClick={() =>
            modal.push(<LoginForm />, {
              tracking: window.location.pathname + ' ~ login: form',
            })
          }
        >
          {t('registration-login-to-account')}
        </Button>
      </footer>
    </main>
  );
}
