import React from 'react';
import { useTranslation } from 'react-i18next';
import { useModal } from 'components/Modal';
import { useMutation } from 'react-query';
import Verification from 'pages/Registration/Verification';
import CreatePassword from 'pages/Registration/CreatePassword';
import BackButton from 'components/BackButton';
import Button from 'components/Button';
import Spinner from 'components/Spinner';
import FormItemPhone from 'components/fields/Phone';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Api from 'Api';

export default function ResetPassword() {
  const { t } = useTranslation('common');
  const modal = useModal();

  const ValidationSchema = Yup.object().shape({
    phone: Yup.string().required(t('validation-required')),
  });

  const { isLoading, mutate: postForm } = useMutation(({ values, actions }) => {
    return Api.forgotPassword(values)
      .then(() => {
        modal.push(
          <Verification
            verify={(code) =>
              modal.push(
                <CreatePassword verificationCode={code} phone={values.phone} />,
                {
                  tracking:
                    window.location.pathname +
                    ' ~ forgot-password: create-new-password',
                }
              )
            }
            length={5}
            withBackButton
          />,
          {
            tracking:
              window.location.pathname +
              ' ~ reset-password: phone-verification',
          }
        );
      })
      .catch((error) => {
        actions.setErrors(error.response.data?.errors);
      });
  });

  return (
    <>
      <header>
        <BackButton cb={modal.pop} />
        <div role="doc-subtitle">{t('reset-password-subtitle')}</div>
        <h1>{t('reset-password-title')}</h1>
        <div role="doc-description">{t('reset-password-description')}</div>
      </header>
      <main style={{ position: 'relative' }}>
        {isLoading && <Spinner floating />}
        <h2>{t('reset-password-form-heading')}</h2>
        <Formik
          initialValues={{ phone: '' }}
          validationSchema={ValidationSchema}
          onSubmit={(values, actions) => postForm({ values, actions })} //because useMutation function accept only one argument!
        >
          {({ values }) => (
            <Form id="reset-password-form">
              <FormItemPhone label={t('form-phone')} name="phone" />
            </Form>
          )}
        </Formik>
        <p>{t('reset-password-help-text')}</p>
      </main>
      <footer>
        <Button type="submit" form="reset-password-form" disabled={isLoading}>
          {t('reset-password-action-button')}
        </Button>
      </footer>
    </>
  );
}
