import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient, useMutation } from 'react-query';
import QueueManagement from 'libs/QueueManagement';
import Verification from 'pages/Registration/Verification';
import FormItemPhone from 'components/fields/Phone';
import BackButton from 'components/BackButton';
import Button from 'components/Button';
import RadioGroup from 'components/RadioGroup';
import Spinner from 'components/Spinner';
import FormItem from 'components/FormItem';
import { useModal } from 'components/Modal';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Api from 'Api';

export default function RegisterForm1() {
  return <RegisterForm2 activeRole={1} />;

  /**
   * Skip intermediate step with selecting role as for now the only role available is farmer (value=1)
   */

  // const { t, i18n } = useTranslation('common');
  // const [activeRole, setActiveRole] = useState(undefined);
  // const modal = useModal();

  // const { data: roles, isFetching: rolesIsFetching } = useQuery(
  //   'roles',
  //   async () => {
  //     const data = await Api.getRoles(i18n.language.substring(0, 2));
  //     return data;
  //   },
  //   { cacheTime: 0, staleTime: 0 } // no caching - always want to check with server
  // );

  // return (
  //   <>
  //     <header style={{ margin: 0 }}>
  //       <BackButton cb={modal.pop} />
  //       <div role="doc-subtitle">{t('registration-create-account')}</div>
  //       <h1>{t('registration-choose-role-title')}</h1>
  //     </header>
  //     {rolesIsFetching ? (
  //       <Spinner />
  //     ) : (
  //       <RadioGroup
  //         name="roles"
  //         items={roles}
  //         activeItem={activeRole}
  //         handler={setActiveRole}
  //         dataKeyAttributeName="id"
  //         checkedIcon
  //         withImages
  //       />
  //     )}

  //     <footer>
  //       <Button
  //         onClick={() =>
  //           modal.push(<RegisterForm2 activeRole={activeRole} />, {
  //             tracking: window.location.pathname + ' ~ create-account: form',
  //           })
  //         }
  //         disabled={activeRole === undefined}
  //       >
  //         {t('button-continue')}
  //       </Button>
  //     </footer>
  //   </>
  // );
}

function RegisterForm2(props) {
  const { t, i18n } = useTranslation('common');
  const modal = useModal();
  const queryClient = useQueryClient();
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    password: '',
    password_confirmation: '',
  });

  const ValidationSchema = Yup.object().shape({
    fullName: Yup.string().required(t('validation-required')),
    email: Yup.string().email(t('validation-email')),
    phone: Yup.string().required(t('validation-required')),
    password: Yup.string()
      .min(8, t('validation-password'))
      .required(t('validation-required')),
    password_confirmation: Yup.string()
      .oneOf([Yup.ref('password'), null], t('validation-passwords-match'))
      .required(t('validation-required')),
  });

  const { isLoading, mutate: postForm } = useMutation(
    async ({ values, actions }) => {
      values['language'] = i18n.language.substring(0, 2);
      values['role_id'] = props.activeRole;
      let timezone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone;
      if (timezone) values['timezone'] = timezone;

      setFormData({
        ...values,
        password: '', //never store password in plain-text even locally
        password_confirmation: '',
      });

      return Api.createUser(values)
        .then(Api.sendVerificationUserPhone)
        .then(async () => {
          const verify = (code) =>
            Api.verifyUserPhone({ verification_code: code }).then(async () => {
              //fetch updated user data
              const userData = await Api.getUser();
              queryClient.setQueryData('user.account', userData);
              modal.close({ animation: false });
            });

          modal.push(
            <Verification
              verify={verify}
              length={6}
              verifyButtonText={t('account-verification-action-button')}
              secondaryButtonText={t('account-verification-skip-button')}
              secondaryButtonOnClick={() => modal.close({ animation: false })}
            />,
            {
              tracking:
                window.location.pathname +
                ' ~ create-account: phone-verification',
            }
          );

          queryClient.removeQueries({ inactive: true }); // clear last user cache
          QueueManagement.clear();
          queryClient.setQueryData('user.authenticated', true);

          Api.getUser().then((userData) => {
            queryClient.setQueryData('user.account', userData);
            localStorage.setItem('user-phone', userData.phone);
          });
        })
        .catch((error) => {
          actions.setErrors(error.response.data?.errors);
        });
    }
  );

  return (
    <>
      <header>
        <BackButton cb={modal.pop} />
        <div role="doc-subtitle">{t('registration-farmer-registration')}</div>
        <h1>{t('registration-introduction-title')}</h1>
        <div role="doc-description">
          {t('registration-introduction-description')}
        </div>
      </header>
      <main style={{ position: 'relative' }}>
        {isLoading && <Spinner floating />}
        <h2>{t('registration-form-heading')}</h2>
        <Formik
          initialValues={formData}
          validationSchema={ValidationSchema}
          onSubmit={(values, actions) => postForm({ values, actions })} //because useMutation function accept only one argument!
        >
          {({ values }) => (
            <Form id="registration-form">
              <FormItem
                type="text"
                name="fullName"
                label={t('form-fullname')}
              />
              <FormItemPhone
                name="phone"
                label={t('form-phone')}
                id="form-phone"
              />
              <FormItem type="email" name="email" label={t('form-email')} />
              <FormItem
                type="password"
                name="password"
                label={t('form-password')}
              />
              <FormItem
                type="password"
                name="password_confirmation"
                label={t('form-password-confirmation')}
              />
            </Form>
          )}
        </Formik>
      </main>
      <footer>
        <Button type="submit" form="registration-form" disabled={isLoading}>
          {t('registration-complete-account')}
        </Button>
      </footer>
    </>
  );
}
