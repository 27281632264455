const config = {
  apiUrl: 'https://farmer-api.blocrice.org/api', //production uses full url, development uses proxy (see proxy in package.json)
  // apiUrl: '/api', // for development, use with proxy in package.json
  staleTime: Infinity /* use cache for queries */,
  cacheTime: Infinity /* use cache for queries */,
  currencyPrefix: '៛',
  // locale: 'km', // km, en-GB           // MODIFIED: use instead: i18n.language | localStorage.getItem('locale')
  // localeDateFns: 'km', //km, enGB      // MODIFIED: use instead: localStorage.getItem('localeDateFns')
  fallbackLocal: 'en-GB', // km, en-GB    // default language to use at first visit
  actionSowingKey: 'sowing',
  actionSowingHarvestField: 'sowed_land_in_hectares',
  cropCycleHarvestField: 'total_harvest',
};

export default config;
