import React, { useState, useEffect, useRef } from 'react';
import PhoneInput from 'react-phone-input-2';
import FormItem from 'components/FormItem';
import 'react-phone-input-2/lib/high-res.css';

import './Phone.scss';

const Phone = ({ placeholder, form, field, ...props }) => {
  const [phone, setPhone] = useState(field.value || '');

  useEffect(() => {
    form.setFieldValue(field.name, phone);
  }, [phone]);

  return (
    <PhoneInput
      onlyCountries={['kh', 'nl']}
      countryCodeEditable={false}
      country={'kh'}
      value={phone}
      onChange={(phone) => setPhone(phone)}
      placeholder={placeholder}
      inputProps={{ id: 'username' }}
    />
  );
};

const FormItemPhone = (props) => {
  const formItem = useRef(null);
  const [computedWidth, setComputedWidth] = useState('0');

  useEffect(() => {
    //move input tag to end of container, after the flag-selector, for better focus order
    let input = formItem.current.querySelector('input.form-control');
    input.parentNode.appendChild(input);

    const handleResize = () => {
      const style = window.getComputedStyle(formItem.current);
      setComputedWidth(style.width);
    };
    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize); // remove listener to prevent memory leak
  }, []);

  return (
    <FormItem
      {...props}
      component={Phone}
      ref={formItem}
      style={{ '--dropdown-width': computedWidth }}
    />
  );
};

export default FormItemPhone;
