import React, { lazy, useState, useRef, useEffect } from 'react';
import Menu from 'components/Menu';
import { Routes, Route, useLocation } from 'react-router-dom';
import { useTracking } from 'react-tracking';
import { useTranslation } from 'react-i18next';

import { useScreenClass } from 'react-grid-system';
import config from 'config';
import Api from 'Api';
import QueueManagement from 'libs/QueueManagement';
import Registration from 'pages/Registration';
import ModalMessageSync from 'components/ModalMessageSync';
import ModalMessageLoginSync from 'components/ModalMessageLoginSync';
import { useQuery, useQueryClient } from 'react-query';
import { ReactComponent as SpinnerSVG } from 'assets/spinner.svg';
import Modal, { bindModal } from 'components/Modal';
import './App.scss';

const Home = lazy(() => import('pages/Home'));
const Crops = lazy(() => import('pages/Crops'));
const Upgrades = lazy(() => import('pages/Upgrades'));
const Profile = lazy(() => import('pages/Profile'));
const ProfileEdit = lazy(() => import('pages/Profile/Edit'));

function App() {
  const { trackEvent } = useTracking();
  const { i18n } = useTranslation('common');
  const location = useLocation();
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(true);
  const { data: queue = [] } = useQuery('queue');
  const { data: isProcessing = false } = useQuery('queue.is_processing');
  const [queueStatus, setQueueStatus] = useState(0);

  const modalRef = useRef(null);
  bindModal(modalRef); //bind the global modal to this instance of AdvanceModal

  const modalFloatingRef = useRef(null);
  bindModal(modalFloatingRef, 'floating');

  const modalMessagesRef = useRef(null);
  const msgModal = bindModal(modalMessagesRef, 'msg');

  const modalBottomSheetRef = useRef(null);
  bindModal(modalBottomSheetRef, 'bottom-sheet');

  QueueManagement.init(queryClient, trackEvent);

  // first await all server data.. if we cannot, the app won't load (no fallbacks)
  const queueCountRef = useRef();
  const { data: isAuthenticated } = useQuery('user.authenticated');

  const processQueueAndFetchData = () => {
    setLoading(true);

    QueueManagement.process().then((res, error) => {
      if (res === 'error') {
        setQueueStatus(isProcessing ? 3 : queue.length > 0 ? 2 : 1);
        // if there's an error with processing the queue, we don't load new data.
        setLoading(false);
        return;
      }
      Api.getAllData(queryClient).then((data) => {
        setLoading(false);
      });
    });
  };

  useEffect(() => {
    // on route change
    trackEvent({
      type: 'page',
      path: location.pathname,
    });
    console.log('route changed', location);
  }, [location]);

  useEffect(() => {
    //setup default language if not existed
    if (!localStorage.getItem('localeDateFns'))
      localStorage.setItem(
        'localeDateFns',
        config.fallbackLocal.replaceAll('-', '')
      );
    document.body.setAttribute('data-lang', i18n.language);

    //check if the auth token is still valid
    Api.getUser()
      .then((res) => {
        if (res?.uuid) {
          queryClient.setQueryData('user.account', res);
          localStorage.setItem('user-phone', res.phone);
          queryClient.setQueryData('user.authenticated', true);
        } else {
          queryClient.setQueryData('user.account', {});
          queryClient.setQueryData('user.authenticated', false);
        }
      })
      .catch((error) => {
        // 500 => cannot reach server - don't change auth status
        if (error.response.status === 500) return;

        queryClient.setQueryData('user.account', {});
        queryClient.setQueryData('user.authenticated', false);
      });
  }, []);

  // this useEffect shouldn't have extra params, so it runs every time tha app is re-rendered to check if the queue is changed.
  useEffect(() => {
    if (queueCountRef.current !== queue.length) {
      console.log('queue has changed ok', queue, 'isprocessing', isProcessing);
      setQueueStatus(isProcessing ? 3 : queue.length > 0 ? 2 : 1);
    }
    queueCountRef.current = queue.length;
  }, [queue]);

  useEffect(() => {
    console.log('is processing has changed', isProcessing);

    setQueueStatus(isProcessing ? 3 : queue.length > 0 ? 2 : 1);
  }, [isProcessing]);

  useEffect(() => {
    if (queueStatus === 2 && isAuthenticated && !loading) {
      const timestamp = Math.round(new Date().getTime() / 1000);

      trackEvent({
        type: 'event',
        cat: 'error-out-of-sync',
        val: `[${timestamp}] Key of next queue item: ${QueueManagement.getNextItemKey()}`,
      });

      msgModal.show(
        <ModalMessageSync
          classes={['floating', 'bottom', 'message']}
          doResync={processQueueAndFetchData}
        />,
        {
          tracking:
            window.location.pathname + ' ~ out-of-sync: sync-now-message',
        }
      );
    }
  }, [queueStatus, loading]);

  useEffect(() => {
    if (isAuthenticated === false && QueueManagement.length()) {
      const timestamp = Math.round(new Date().getTime() / 1000);

      trackEvent({
        type: 'event',
        cat: 'error-out-of-sync-unauthenticated',
        val: `[${timestamp}] Key of next queue item: ${QueueManagement.getNextItemKey()}`,
      });

      msgModal.show(<ModalMessageLoginSync />, {
        tracking:
          window.location.pathname + ' ~ message-out-of-sync-unauthenticated',
      });
    }

    if (isAuthenticated === false && !QueueManagement.length()) {
      console.log('All tasks is synced, clear last user cache');
      localStorage.removeItem('user-phone');
      queryClient.removeQueries({ inactive: true }); // clear last user cache
    }

    // fetch data once user isAuthenticated
    if (isAuthenticated) processQueueAndFetchData();
  }, [isAuthenticated]);

  return (
    <>
      <div id="app" className={useScreenClass()} queue_status={queueStatus}>
        {isAuthenticated ? (
          loading ? (
            <SpinnerSVG />
          ) : (
            <>
              <Routes>
                <Route
                  path="/"
                  element={
                    <Home
                      queueStatus={queueStatus}
                      doResync={processQueueAndFetchData}
                    />
                  }
                />
                <Route path="/crops" exact element={<Crops />} />
                <Route path="/upgrades" element={<Upgrades />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/profile/edit" element={<ProfileEdit />} />
              </Routes>
              <Menu />
            </>
          )
        ) : (
          <Registration />
        )}

        <Modal
          ref={modalBottomSheetRef}
          classNameOverlay="overlay"
          className="modal bottom-sheet"
          type="bottom-sheet"
          bottomSheetOptions={{ drag: false }}
        />
        <Modal
          ref={modalMessagesRef}
          classNameOverlay="overlay"
          className="modal floating bottom message"
          type="floating"
          position="bottom"
        />
        <Modal
          ref={modalFloatingRef}
          classNameOverlay="overlay"
          className="modal floating"
          type="floating"
        />
        <Modal
          ref={modalRef}
          classNameOverlay="overlay"
          className="modal"
          type="full-page"
        />
      </div>
    </>
  );
}

export default App;
