import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { QueryClientProvider, QueryClient } from 'react-query';
import { persistQueryClient } from 'react-query/persistQueryClient-experimental';
import { createWebStoragePersistor } from 'react-query/createWebStoragePersistor-experimental';
import { ReactQueryDevtools } from 'react-query/devtools';
import { I18nextProvider } from 'react-i18next';
import ReactPiwik from 'react-piwik';

import track from 'react-tracking';
import i18next from 'i18next';

import './index.scss';
import App from './App';
import config from 'config';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { setConfiguration, ScreenClassProvider } from 'react-grid-system';

import Spinner from 'components/Spinner';

import strings_en from 'translations/en.json';
import strings_km from 'translations/km.json'; /* khmer */

const piwik = new ReactPiwik({
  url: 'matomo.schuttelaar.net',
  siteId: 74,
});

const trackAtConnect = false;

i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: localStorage.getItem('locale'), // language to use
  fallbackLng: config.fallbackLocal, // default language at start
  resources: {
    'en-GB': {
      common: strings_en, // 'common' is our custom namespace
    },
    km: {
      common: strings_km,
    },
  },
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: Infinity,
      staleTime: Infinity,
      queryFn: async ({ queryKey }) => {
        try {
          // empty function will use cache
          await queryClient.prefetchQuery(queryKey, () => {});
        } catch (error) {
          console.log('error', error);
        }
      },
    },
  },
});

const localStoragePersistor = createWebStoragePersistor({
  storage: window.localStorage,
});

persistQueryClient({
  queryClient,
  persistor: localStoragePersistor,
});

setConfiguration({ defaultScreenClass: 'sm' });

const TrackedApp = track(
  { Component: 'App' }, // this will be added to data for the dispatch callback
  {
    dispatch: (data) => {
      switch (data.type) {
        case 'page':
          ReactPiwik.push(['setCustomUrl', data.path]);
          //ReactPiwik.push(['setDocumentTitle', 'My New Title']);
          ReactPiwik.push(['trackPageView']);
          break;
        case 'event':
          ReactPiwik.push(['trackEvent', data.cat, data.val]);
          break;
      }

      console.log('track', data);
    },
  }
)(App);

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <QueryClientProvider client={queryClient}>
        <ScreenClassProvider>
          <BrowserRouter>
            <Suspense fallback={<Spinner floating />}>
              <TrackedApp />
            </Suspense>
          </BrowserRouter>
        </ScreenClassProvider>
        <ReactQueryDevtools />
      </QueryClientProvider>
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onUpdate: (reg) => {
    console.log('got an update', reg);
    if (reg.waiting) {
      console.log(
        'installed new SW, its ready. send skip waiting message so it takes over control'
      );
      reg.waiting.postMessage({ type: 'SKIP_WAITING' });
      window.location.reload();
    }
  },
});

// Refresh the page when a new serviceworker becomes active
let refreshing = false;
navigator.serviceWorker.addEventListener('controllerchange', () => {
  console.log(
    'controllerchange: new service worker has been activated. it is now controlling the clients. Now going to auto refresh the client to load updated static files'
  );
  if (refreshing) return;
  refreshing = true;
  window.location.reload();
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
