import axios from 'axios';
import config from 'config';
import fileManagement from 'libs/fileManagement';

class Api {
  data = {};
  options = {
    withCredentials: true,
    headers: {
      //Authorization: `Bearer RKr1Jaof6ktAWSPV4Z8cMZbdrGPMnf4R1T0mH7Kd`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  getAllData = async (queryClient) => {
    // fetch data from server & store with react query. Should be the only fetch we need.
    try {
      const [resultData, resultUserData, surveyData, profileData] =
        await Promise.all([
          axios.get(`${config.apiUrl}/combined`, this.options),
          axios.get(`${config.apiUrl}/user/data`, this.options),
          axios.get(`${config.apiUrl}/user/fields/survey`, this.options),
          axios.get(`${config.apiUrl}/user/fields/profile`, this.options),
        ]);

      console.log('all: ', resultData, resultUserData, surveyData, profileData);

      // clear complete client cache
      queryClient.removeQueries('user.data');
      queryClient.removeQueries('data');
      queryClient.removeQueries('survey');

      for (const field of profileData.data[0]?.fields)
        queryClient.setQueryData(`data.${field.key}`, field);

      for (const [key, value] of Object.entries(resultData.data))
        queryClient.setQueryData(`data.${key}`, value);

      for (let [key, value] of Object.entries(resultUserData.data))
        switch (key) {
          case 'survey_fields':
            value.forEach((survey) => {
              queryClient.setQueryData(
                ['user.data.survey', survey.key],
                survey
              );
            });
            break;

          case 'notifications':
            // For now, all notifications are of type 'Payment Request', only show pending transactions
            queryClient.setQueryData(
              `user.data.notifications`,
              value.filter(
                (e) =>
                  e.confirmation.status === 'pending' &&
                  e.type === 'notification-payment-request'
              )
            );
            break;

          // set individual cycle actions for each crop_cycle as we store them separately here
          case 'crop_cycles':
            value.forEach((cycle) => {
              queryClient.setQueryData(
                ['user.data.cycle_actions', cycle.key],
                () => cycle.field_actions
              );
            });
            queryClient.setQueryData(`user.data.${key}`, value);
            break;

          default:
            queryClient.setQueryData(`user.data.${key}`, value);
        }

      queryClient.setQueryData('survey.sections', (oldData) => {
        return surveyData.data;
      });

      return [resultData.data, resultUserData.data];
    } catch (error) {
      console.log('error', error.response.data.error);
    }
  };

  post = async (item) => {
    const options = { ...this.options };
    delete options.headers['Content-Type'];

    let postData = null;
    const key = typeof item.key === 'object' ? item.key[0] : item.key;
    let endpoint = config.apiUrl;
    switch (key) {
      // first endpoint, others need to be added still
      case 'user.data.cycle_actions':
        endpoint += `/cycle/${item.key[1]}/action`;
        // do something
        break;
      case 'user.data.crop_cycles':
        endpoint += '/cycle';
        break;
      case 'user.data.upgrades':
        endpoint += '/upgrade';
        break;
      case 'user.data.survey':
        endpoint += '/user/survey';
        break;
      case 'user.fields':
        endpoint += '/user/profile-information';
        break;
      case 'upload':
        endpoint += '/upload';
        break;
      default:
        break;
    }

    if (key === 'upload') {
      const fileNames = item.item.file_names;
      if (!fileNames) return;
      const formData = new FormData();

      await Promise.all(
        fileNames.map(async (fileName) => {
          const blob = await fileManagement.getFile(fileName);
          formData.append('file[]', blob, fileName);
        })
      );

      options.headers['Content-Type'] = 'multipart/form-data';
      for (let key in item.item) {
        if (key === 'file_names') continue;
        formData.append(key, item.item[key]);
      }
      postData = formData;
    } else {
      postData = item.item;
    }

    try {
      const res = await axios.post(endpoint, postData, options);
      return Promise.resolve(res);
    } catch (error) {
      return Promise.resolve({ error: error.response });
    }
  };

  getTokenCookie = () => {
    return axios.get(`${config.apiUrl}/sanctum/csrf-cookie`, this.options);
  };

  createUser = async (userData) => {
    return axios.post(`${config.apiUrl}/user`, userData, this.options);
  };

  editUser = async (userData) => {
    return axios.post(
      `${config.apiUrl}/user/profile-information`,
      userData,
      this.options
    );
  };

  sendVerificationUserPhone = async () => {
    return axios.get(`${config.apiUrl}/verification/phone/start`, this.options);
  };

  verifyUserPhone = async (verificationData) => {
    return axios.post(
      `${config.apiUrl}/verification/phone/verify`,
      verificationData,
      this.options
    );
  };

  loginUser = async (userData) => {
    return axios.post(`${config.apiUrl}/login`, userData, this.options);
  };

  logoutUser = async () => {
    return axios.get(`${config.apiUrl}/logout`, this.options);
  };

  forgotPassword = async (data) => {
    return axios.post(`${config.apiUrl}/forgot-password`, data, this.options);
  };

  resetPassword = async (data) => {
    return axios.post(`${config.apiUrl}/reset-password`, data, this.options);
  };

  updatePassword = async (data) => {
    return axios.post(`${config.apiUrl}/update-password`, data, this.options);
  };

  getUser = async () => {
    return axios
      .get(`${config.apiUrl}/user`, this.options)
      .then((res) => res.data);
  };

  getRoles = async (lang) => {
    const options = { ...this.options };

    // need to get strings in default language
    options.params = { lang };

    const data = await axios.get(`${config.apiUrl}/roles`, options);
    return data.data;
  };

  readNotification = async (notification_id) => {
    return await axios.post(
      `${config.apiUrl}/notification/read`,
      { notification_id },
      this.options
    );
  };

  acceptTransaction = async (confirmation_id) => {
    return await axios.post(
      `${config.apiUrl}/confirmation/accept`,
      { confirmation_id },
      this.options
    );
  };

  rejectTransaction = async (confirmation_id) => {
    return await axios.post(
      `${config.apiUrl}/confirmation/reject`,
      { confirmation_id },
      this.options
    );
  };
}

export default new Api();
