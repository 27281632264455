import React from 'react';
import './Grid.scss';

const GridContext = React.createContext(); //note - could also be done with React.children.map, adding it as a prop to the children.

export function Container({ children, ...otherAttributes }) {
  return (
    <div
      className="container"
      /* use {...otherAttributes} to pass attributes like 'disabled' */
      {...otherAttributes}
    >
      {children}
    </div>
  );
}

export function ContainerFull({ children, ...otherAttributes }) {
  return (
    <div
      className="container-full"
      /* use {...otherAttributes} to pass attributes like 'disabled' */
      {...otherAttributes}
    >
      {children}
    </div>
  );
}

export function Grid({ children, cols, ...otherAttributes }) {
  return (
    <GridContext.Provider value={{ cols }}>
      <div
        className="grid"
        /* use {...otherAttributes} to pass attributes like 'disabled' */
        {...otherAttributes}
      >
        {children}
      </div>
    </GridContext.Provider>
  );
}

export function Item(props) {
  let settings = React.useContext(GridContext) || {
    cols: 1,
  };
  const { style, ...otherAttributes } = props;

  return (
    <div
      className="item"
      style={{ width: `${100 / settings.cols}%`, ...style }}
      /* use {...otherAttributes} to pass attributes like 'disabled' */
      {...otherAttributes}
    ></div>
  );
}
